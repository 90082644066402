import { useState, useTransition } from "react";

import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

const SearchForm = styled("form")(({ theme }) => ({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  border: "1px solid rgba(0, 0, 0, .250)",
  height: theme.spacing(4),
  borderRadius: "2px",
}));

interface Props {
  title: string;
  onSearch?: (search: string) => void;
}

export default function SearchBar({ title, onSearch }: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [isPending, startTransition] = useTransition();

  const search = searchParams.get("search");
  const [searchString, setSearchString] = useState(search || "");

  const searchHandler = (search: string) => {
    searchParams.set("search", search);
    startTransition(() => setSearchParams(searchParams));
    if (onSearch) {
      onSearch(searchString);
    }
  };

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchString(e.target.value);
  }

  return (
    <SearchForm
      data-testid="search-bar"
      onSubmit={(e) => {
        searchHandler(searchString);
        e.preventDefault();
      }}
    >
      <IconButton aria-label="search" size="large" type="submit">
        {isPending ? <CircularProgress size={15} /> : <SearchIcon />}
      </IconButton>
      <InputBase
        inputProps={{
          "aria-label": `Search ${title}`,
        }}
        onChange={handleInputChange}
        placeholder={title}
        sx={{
          ml: 1,
          flex: 1,
          fontSize: (theme) => theme.typography.pxToRem(12),
          fontFamily: ["Overpass Mono Variable", "monospace"].join(", "),
        }}
        value={searchString}
      />
    </SearchForm>
  );
}
