import { useMemo } from "react";

import { DEFAULT_PAGE_SIZE } from "app/utils/consts";

import type { GridColDef } from "@mui/x-data-grid-pro";

/**
 * Setup columns, enabling clientside filtering and sorting when the
 * number of total rows is less than the pagination limit
 * @param columns GridColumns<any>
 * @param totalRows number
 * @returns GridColumns<any>
 */
export function useColumnSetup(columns: GridColDef[], totalRows: number) {
  return useMemo(() => {
    if (totalRows < DEFAULT_PAGE_SIZE) {
      return columns;
    }

    return columns.map((column) => {
      if (!column.sortable) {
        column.sortable = false;
      }
      column.filterable = false;
      return column;
    });
  }, [totalRows, columns]);
}
