import type React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { COLORS, Card } from "@stacklet/ui";

interface Props {
  title: string;
  message: string | React.ReactNode;
  children: React.ReactNode;
  menu?: React.ReactNode;
  description?: string;
}

export default function StatCard({
  description = " ",
  title,
  message,
  menu,
  children,
}: Props) {
  return (
    <Card
      sx={{
        background: COLORS.navy.L95,
        p: 3,
      }}
    >
      <Grid container>
        <Grid xs={menu ? 4 : 12} item>
          <Typography
            sx={{
              mb: 1,
              fontWeight: 700,
              color: COLORS.navy.L30,
              textTransform: "capitalize",
            }}
            variant="h2"
          >
            {title}
          </Typography>
        </Grid>
        {menu ? (
          <Grid
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: -1.5 }}
            xs={8}
            item
          >
            {menu}
          </Grid>
        ) : null}

        <Grid xs={12} item>
          <Typography sx={{ minHeight: "22px" }} variant="body2">
            {description}
          </Typography>

          <Typography
            sx={{
              mb: 1,
              color: COLORS.navy.L30,
              fontWeight: 700,
              fontSize: (theme) => theme.typography.pxToRem(72),
              lineHeight: "120%",
            }}
          >
            {children}
          </Typography>
          <Typography
            color={COLORS.navy.L30}
            sx={{
              opacity: 0.8,
              fontSize: (theme) => theme.typography.pxToRem(12),
              lineHeight: "150%",
            }}
            variant="caption"
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
