import { useContext } from "react";

import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { useDeepCompareMemo } from "use-deep-compare";

import { HumanTimestamp } from "app/components/HumanTimestamp";
import { TableLink } from "app/components/links";
import Table from "app/components/table/XGridTable";
import { ProviderContext } from "app/contexts";
import {
  BindingStatusBar,
  useBindingStatus,
} from "app/views/admin/bindings/components/BindingStatus";

import type { GridColDef } from "@mui/x-data-grid-pro";

type Granularity = "month" | "week" | "hour";

interface Props {
  granularity?: Granularity;
}

export function Bindings({ granularity = "month" }: Props) {
  const provider = useContext(ProviderContext);
  const { getBindingProgress } = useBindingStatus();

  const title = "Bindings";
  const segments = {
    month: "BindingRun.last_30_days",
    week: "BindingRun.last_7_days",
    hour: "BindingRun.last_60_minutes",
  } as const;

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      measures: [
        "BindingRun.count",
        "BindingRun.latest_start_time",
        "BindingRun.median_run_time",
        "BindingRun.avg_run_time",
      ],
      order: {
        "BindingRun.count": "desc",
      },
      dimensions: ["Binding.uuid", "Binding.name", "PolicyCollection.provider"],
      segments: [segments[granularity]],
      filters: [
        {
          member: "PolicyCollection.provider",
          operator: "equals",
          values: [provider],
        },
      ],
    },
    { subscribe: granularity === "hour" },
  );

  const data = useDeepCompareMemo(() => resultSet?.tablePivot(), [resultSet]);

  const rows = (data ?? []).map((row) => {
    return {
      id: `${row["Binding.name"]}-${row["BindingRun.latest_start_time"]}`,
      uuid: row["Binding.uuid"],
      count: row["BindingRun.count"],
      name: row["Binding.name"],
      lastStart: row["BindingRun.latest_start_time"],
      avgTime: row["BindingRun.avg_run_time"],
    };
  });

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
  }

  if (isLoading || !resultSet) {
    return (
      <>
        <Typography sx={{ mb: 1 }} variant="h3">
          {title}
        </Typography>
        <Skeleton
          animation="wave"
          height={500}
          role="progressbar"
          sx={{ mb: 2 }}
          variant="rectangular"
        />
      </>
    );
  }

  type BindingRow = (typeof rows)[0];

  const columns: GridColDef<BindingRow>[] = [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      width: 400,
      renderCell: (params) => {
        const uuid = params.row.uuid;
        const progress = getBindingProgress(uuid.toString());
        return (
          <Stack>
            <TableLink name={params.value} to={`/admin/bindings/${uuid}`} />
            <BindingStatusBar progress={progress} />
          </Stack>
        );
      },
    },
    {
      headerName: "Last start",
      field: "lastStart",
      flex: 1,
      renderCell: (params) => <HumanTimestamp timestamp={params.value} />,
    },
    {
      headerName: "Average run time (s)",
      field: "avgTime",
      flex: 1,
      type: "number",
      valueGetter: (params) => parseFloat(params.value).toFixed(2),
    },
    {
      headerName: "Run count",
      field: "count",
      flex: 1,
      type: "number",
    },
  ];

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h3">
        {title}
      </Typography>

      <Table
        columns={columns}
        getRowId={(row) => row.id}
        hasToolbar={false}
        rows={rows}
        tableId="activity-recurring-policies"
      />
    </>
  );
}
