import { setWithExpiry, getWithExpiry } from "app/utils/sessionStorage";

import type { Headers } from "react-relay-network-modern/lib";

type Configs = {
  cognito: {
    cognito_install: string;
    cognito_user_pool_region: string;
    cognito_user_pool_id: string;
    cognito_user_pool_client_id: string;
    saml: {
      [name: string]: string;
    };
  };

  cubejs: {
    cubejs_domain: string;
  };

  deployment: {
    gql_uri: string;
    headers?: Headers;
  };

  docs?: {
    docs_url: string;
  };

  redash: {
    redash_uri: string;
    redash_api_token?: string;
  };

  sinistral: {
    sinistral_url: string;
  };
};

async function _fetchConfigs(): Promise<Configs> {
  return {
    cognito: await (await fetch("/config/cognito.json")).json(),
    cubejs: await (await fetch("/config/cubejs.json")).json(),
    deployment: await (await fetch("/config/deployment.json")).json(),
    docs: await (await fetch("/config/docs.json")).json(),
    redash: await (await fetch("/config/redash.json")).json(),
    sinistral: await (await fetch("/config/sinistral.json")).json(),
  };
}

export default async function getConfigs(): Promise<Configs> {
  let configs: Configs;
  const configsJSON = getWithExpiry("configs");
  if (configsJSON) {
    configs = JSON.parse(configsJSON);
  } else {
    configs = await _fetchConfigs();
    setWithExpiry("configs", JSON.stringify(configs), 60);
  }
  return configs;
}
