/**
 * @generated SignedSource<<c21c315513dc994e97d31a4b9d6d0579>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
export type createUrlConfigsQuery$variables = Record<PropertyKey, never>;
export type createUrlConfigsQuery$data = {
  readonly UrlConfig: {
    readonly __typename: "UrlConfig";
  } | null | undefined;
};
export type createUrlConfigsQuery = {
  response: createUrlConfigsQuery$data;
  variables: createUrlConfigsQuery$variables;
};

const node: ClientRequest = (function(){
var v0 = [
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UrlConfig",
        "kind": "LinkedField",
        "name": "UrlConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "createUrlConfigsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "createUrlConfigsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6f56bddeee30c3cbb6ba8b30dd145fac",
    "id": null,
    "metadata": {},
    "name": "createUrlConfigsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6b4b62a6df0d62b541d89de439b53f37";

export default node;
