import { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { Button, Paper } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useMutation, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";

import ExportCsv from "app/components/csv-export/ExportCsv";
import DetailsMetaData from "app/components/DetailsMetaData";
import { DeleteConfirmationDialog } from "app/components/dialogs";
import NoData from "app/components/empty-state/NoData";
import DetailsGridItem from "app/components/grid/DetailsGridItem";
import { ProviderIcon } from "app/components/icons/ProviderIcon";
import { TableLink } from "app/components/links";
import Table from "app/components/table/XGridTable";
import TableActionMenu from "app/components/TableActionMenu";
import { useAlertContext } from "app/contexts/AlertContext";
import { useLoadNext } from "app/hooks";
import { RemoveAccountGroupMappingsMutation } from "app/mutations";

import { Remove } from "../components/Remove";

import type {
  AccountGroupDetails_accountMappings$key,
  CloudProvider,
} from "./__generated__/AccountGroupDetails_accountMappings.graphql";
import type { AccountGroupDetailsRefetchQuery } from "./__generated__/AccountGroupDetailsRefetchQuery.graphql";
import type { GridColDef } from "@mui/x-data-grid-pro";
import type {
  RemoveAccountGroupMappingsMutation$data,
  RemoveAccountGroupMappingsMutation as RemoveAccountGroupMappingsMutationType,
} from "app/mutations/__generated__/RemoveAccountGroupMappingsMutation.graphql";
import type { RecordSourceSelectorProxy } from "relay-runtime";

type Account = {
  name: string;
  mappingId: string;
  key: string;
  provider: CloudProvider;
};

interface Props {
  accountGroup: AccountGroupDetails_accountMappings$key;
  showActions?: boolean;
}

const ActionButton = styled("div")(({ theme }) => ({
  "&+.actionButton": {
    marginLeft: theme.spacing(1),
  },
}));

export function AccountGroupDetails({
  accountGroup,
  showActions = true,
}: Props) {
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);

  const { alertDispatch } = useAlertContext();
  const navigate = useNavigate();

  const [mappingToBeRemoved, setMappingToBeRemoved] = useState<
    Account | undefined
  >();
  const [isDeleteConfirmDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState<boolean>(false);

  const { data, loadNext, hasNext } = usePaginationFragment<
    AccountGroupDetailsRefetchQuery,
    AccountGroupDetails_accountMappings$key
  >(
    graphql`
      fragment AccountGroupDetails_accountMappings on AccountGroup
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        filterElement: { type: "FilterElementInput" }
      )
      @refetchable(queryName: "AccountGroupDetailsRefetchQuery") {
        accountMappings(
          first: $first
          last: $last
          after: $after
          before: $before
          filterElement: $filterElement
        ) @connection(key: "AccountGroupDetails_accountMappings") {
          __id
          edges {
            node {
              id
              account {
                key
                name
                provider
                status
                status_message
              }
            }
          }
          pageInfo {
            __id
            total
          }
        }
        dynamicFilter
        uuid
        name
        provider
        description
        dynamicFilter
        system
        regions
      }
    `,
    accountGroup,
  );

  const loadMore = useLoadNext(hasNext, loadNext);

  const openDialog = (value: boolean) => {
    setIsConfirmDeleteDialogOpen(value);
  };

  const deleteAccountFromGroup = () => {
    const ids = mappingToBeRemoved?.mappingId
      ? [mappingToBeRemoved.mappingId]
      : [];
    const removedCount = ids.length;
    setMappingToBeRemoved(undefined);
    setIsConfirmDeleteDialogOpen(false);

    function updatePageInfo(
      id: string,
      store: RecordSourceSelectorProxy<RemoveAccountGroupMappingsMutation$data>,
    ) {
      const record = store.get(id);
      const total = record?.getValue("total") as number;
      if (record && total) {
        record.setValue(total - removedCount, "total");
      }
    }

    commit({
      variables: {
        input: {
          ids,
        },
      },
      onCompleted: (response, errors) => {
        if (errors) {
          alertDispatch({
            message: `Error: ${errors[0]?.message}`,
            severity: "error",
          });
        } else {
          alertDispatch({
            message: "successfully removed account from account group",
            severity: "success",
          });
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
      optimisticUpdater: (store) => {
        updatePageInfo(data.accountMappings.pageInfo.__id, store);
        ids.map((id) => store.delete(id));
      },
      updater: (store) => {
        updatePageInfo(data.accountMappings.pageInfo.__id, store);
      },
    });
  };

  const [commit] = useMutation<RemoveAccountGroupMappingsMutationType>(
    RemoveAccountGroupMappingsMutation,
  );

  const confirmDelete = () => {
    deleteAccountFromGroup();
  };

  const rows = (data?.accountMappings?.edges ?? [])
    .filter((edge) => edge.node)
    .map((edge) => {
      const { account, id } = edge.node;
      return {
        key: account.key,
        name: account.name,
        mappingId: id,
        provider: account.provider,
        status: account.status,
        status_message: account.status_message,
      };
    });

  const hasDynamicFilter = useMemo(
    () => data?.dynamicFilter !== undefined && data?.dynamicFilter !== null,
    [data],
  );

  type AccountGroupRow = (typeof rows)[0];

  const columns: GridColDef<AccountGroupRow>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        renderCell: (params) => {
          const { name, key, provider, status, status_message } = params.row;

          const url = `/admin/accounts/details/${encodeURIComponent(
            provider,
          )}/${encodeURIComponent(key)}`;

          return (
            <TableLink
              errorMessage={
                !status && status_message ? status_message : undefined
              }
              name={name}
              to={url}
              copyButton
            />
          );
        },
      },
      {
        headerName: "Key",
        field: "key",
        type: "string",
      },
      {
        headerName: "",
        field: "removeAccount",
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          const account = params.row;
          if (!hasDynamicFilter && showActions) {
            return (
              <TableActionMenu>
                <MenuItem
                  aria-label="remove account from group"
                  onClick={() => {
                    setIsConfirmDeleteDialogOpen(true);
                    setMappingToBeRemoved(account);
                  }}
                >
                  remove
                </MenuItem>
              </TableActionMenu>
            );
          }
        },
      },
    ],
    [hasDynamicFilter, showActions],
  );

  const columnsCsvExport = [
    { name: "Name", path: "account.name" },
    { name: "Key", path: "account.key" },
  ];

  let dynamicFilterMeta;
  if (!hasDynamicFilter) {
    dynamicFilterMeta = "false";
  } else if (data.dynamicFilter === "") {
    dynamicFilterMeta = "all accounts";
  } else {
    dynamicFilterMeta = `"${data.dynamicFilter}"`;
  }

  const rowCount = data?.accountMappings?.pageInfo.total || rows.length;

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper>
        <Box display="flex" justifyContent="space-between">
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography variant="subtitle1">{data.name}</Typography>
          </Stack>
          {showActions ? (
            <Box data-testid="action-button-wrapper" display="flex">
              <ActionButton className={"actionButton"}>
                <Tooltip
                  data-testid="edit-ag-tooltip"
                  title={
                    data.system
                      ? "Can not edit system created account groups"
                      : ""
                  }
                >
                  <span>
                    <Button
                      buttonType="outline-main"
                      disabled={data.system}
                      onClick={() => navigate("edit")}
                    >
                      Edit
                    </Button>
                  </span>
                </Tooltip>
              </ActionButton>
              <ActionButton className={"actionButton"}>
                <Remove
                  accountGroupUuid={data.uuid}
                  isSystemCreated={data.system}
                />
              </ActionButton>
            </Box>
          ) : null}
        </Box>
      </Paper>
      <DetailsMetaData>
        <Grid alignItems="center" columnGap={18} sx={{ pb: 4 }} container>
          <Grid item>
            <Box display="flex">
              {data.provider ? <ProviderIcon provider={data.provider} /> : null}
              <Box alignItems="center" display="flex" ml={2}>
                {data.name}
              </Box>
            </Box>
          </Grid>
          <DetailsGridItem
            direction="row"
            title="Number of accounts"
            value={data.accountMappings?.pageInfo?.total || 0}
          />
        </Grid>
        <Grid alignItems="center" direction="row" spacing={8} container>
          <DetailsGridItem
            direction="column"
            title="Regions"
            value={data.regions?.join(", ") || ""}
          />
          <DetailsGridItem
            direction="column"
            title="Number of regions"
            value={data.regions?.length || 0}
          />
          <DetailsGridItem
            direction="column"
            title="Dynamic"
            value={dynamicFilterMeta}
          />
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        <Grid alignItems="center" columnSpacing={8} container>
          <DetailsGridItem
            direction="column"
            title="description"
            value={data.description || "--"}
          />
        </Grid>
      </DetailsMetaData>
      <Box my={4}>
        <Paper>
          {rows?.length ? (
            <>
              <ExportCsv
                columns={columnsCsvExport}
                exportInProgress={exportInProgress}
                field="accountMappings"
                markComplete={() => setExportInProgress(false)}
                nodeId={data?.id}
                rowCount={rowCount}
              />

              <Table
                columns={columns}
                getRowId={(row) => row.key}
                handleExport={() => setExportInProgress(true)}
                onRowsScrollEnd={loadMore}
                rowCount={rowCount}
                rows={rows}
                tableId="admin-clouds-account-groups-details"
              />
            </>
          ) : (
            <NoData message="There are no accounts in this group." />
          )}
        </Paper>
      </Box>
      <DeleteConfirmationDialog
        action={"Remove"}
        confirmDelete={confirmDelete}
        isDialogOpen={isDeleteConfirmDialogOpen}
        openDialog={openDialog}
        title="account from group"
      />
    </Box>
  );
}
