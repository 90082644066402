import { useEffect, useState } from "react";

import getConfigs from "app/utils/configs";

export function useCubejsConfig(): string | null {
  const [apiUrl, setApiUrl] = useState<string | null>(null);

  useEffect(() => {
    async function fetchConfig() {
      const configs = await getConfigs();
      setApiUrl(
        `${import.meta.env.NODE_ENV === "development" ? "ws" : "wss"}://${
          configs.cubejs.cubejs_domain
        }/cubejs-api/v1`,
      );
    }

    if (!apiUrl) {
      fetchConfig();
    }
  }, [apiUrl, setApiUrl]);

  return apiUrl;
}
