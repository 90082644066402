import { useContext } from "react";

import { useCubeQuery } from "@cubejs-client/react";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { ResponsiveLine } from "@nivo/line";
import * as Sentry from "@sentry/browser";
import { CATEGORICAL_CHART_COLORS, Tooltip } from "@stacklet/ui";
import { addDays, format, subDays } from "date-fns";
import { useDeepCompareMemo } from "use-deep-compare";

import { ProviderContext } from "app/contexts";
import { ChartErrorBoundary } from "app/views/dashboards/components";

const CHART_COLORS = CATEGORICAL_CHART_COLORS;

type Granularity = "day" | "hour" | "minute";

interface Props {
  granularity?: Granularity;
}

export function PolicyExecutionsCount({ granularity = "day" }: Props) {
  const provider = useContext(ProviderContext);
  const chartTitle = "Event-mode executions";
  const today = new Date();
  const dateRange = {
    day: [
      format(subDays(today, 30), "yyyy-MM-dd"),
      format(addDays(today, 1), "yyyy-MM-dd"),
    ],
    hour: "last 168 hours", // 7 days * 24 hours
    minute: "last 60 minutes",
  } as const;

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      measures: ["PolicyExecution.count", "PolicyExecution.resource_count"],
      timeDimensions: [
        {
          dimension: "PolicyExecution.start",
          granularity,
          dateRange: dateRange[granularity],
        },
      ],
      order: {
        "PolicyExecution.count": "desc",
      },
      segments: ["PolicyExecution.only_events"],
      dimensions: [
        "PolicyExecution.account",
        "PolicyExecution.region",
        "PolicyExecution.provider",
      ],
      filters: [
        {
          member: "PolicyExecution.provider",
          operator: "equals",
          values: [provider],
        },
      ],
    },
    { subscribe: granularity === "minute" },
  );

  const data = useDeepCompareMemo(() => {
    const pivot = resultSet?.chartPivot();
    const resourceCounts = (pivot ?? []).map((row) => {
      const count = Object.entries(row)
        .filter(([key, value]) => (key.endsWith(".resource_count") ? value : 0))
        .reduce((sum, cur) => sum + cur[1], 0);
      return { x: row.x, y: count };
    });
    const counts = (pivot ?? []).map((row) => {
      const count = Object.entries(row)
        .filter(([key, value]) => (key.endsWith(".count") ? value : 0))
        .reduce((sum, cur) => sum + cur[1], 0);
      return { x: row.x, y: count };
    });

    return [
      {
        id: "resource-count",
        data: resourceCounts,
        label: "Resources",
        color: CHART_COLORS[0],
      },
      {
        id: "count",
        data: counts,
        label: "Executions",
        color: CHART_COLORS[1],
      },
    ];
  }, [resultSet]);

  const maxResourceCountY = Math.max(...data[0].data.map((item) => item.y));
  const maxCountY = Math.max(...data[1].data.map((item) => item.y));
  const max = Math.max(maxResourceCountY, maxCountY);

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
  }

  if (isLoading || !resultSet) {
    return (
      <>
        <Typography sx={{ mb: 1 }} variant="h3">
          {chartTitle}
        </Typography>
        <Skeleton
          animation="wave"
          height={300}
          role="progressbar"
          variant="rectangular"
        />
      </>
    );
  }

  const axisFormatter = {
    day: "%m/%d",
    hour: "%m/%d",
    minute: "%H:%M",
  };

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h3">
        {chartTitle}
        <Tooltip
          title={
            "The number of executions triggered by event-mode policies and the number of matched resources."
          }
        >
          <span>
            <InfoIcon fontSize="inherit" sx={{ ml: 0.5, mb: -0.2 }} />
          </span>
        </Tooltip>
      </Typography>
      <Box sx={{ height: "300px", mb: 2 }}>
        <ChartErrorBoundary>
          {data ? (
            <ResponsiveLine
              axisBottom={{
                format: axisFormatter[granularity],
                tickValues: 5,
              }}
              axisLeft={{
                tickSize: 0,
                tickValues: 5,
                legend: "# Executions",
                legendOffset: -50,
                legendPosition: "end",
                format: (e) => (Math.floor(e) === e ? e : ""), // only integer ticks
              }}
              colors={CHART_COLORS}
              data={data}
              enableSlices="x"
              legends={[
                {
                  anchor: "bottom-right",
                  data: data.map((i) => ({
                    id: i.id,
                    color: i.color,
                    label: i.label,
                  })),
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              margin={{ top: 50, right: 120, bottom: 32, left: 60 }}
              xFormat="time:%Y-%m-%dT%H:%M:%S.%L"
              xScale={{
                type: "time",
                format: "%Y-%m-%dT%H:%M:%S.%L",
                precision: granularity,
              }}
              yScale={{
                type: "linear",
                min: 0,
                max: max + 0.1 * max || 100,
              }}
            />
          ) : null}
        </ChartErrorBoundary>
      </Box>
    </>
  );
}
