import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import { endOfMonth, format, subMonths } from "date-fns";

import { PercentChangeMessage, percentChanged } from "../../PercentChange";
import StatCard from "../../StatCard";
import { FindingsMenu } from "./FindingsMenu";

import type { Query } from "@cubejs-client/core";
import type { $TSFixMe } from "App";

function buildQuery(
  dateRange: string | [string, string],
  dashboardKey: string,
  provider: string | null,
  sectionKey?: string,
): Query[] {
  return [
    {
      measures: ["ControlSignal.distinctResources"],
      timeDimensions: [
        {
          dimension: "ControlSignal.date",
          granularity: "day",
          dateRange,
        },
      ],
      filters: [
        {
          member: "Dashboard.key",
          operator: "equals",
          values: [dashboardKey],
        },
        ...(sectionKey
          ? [
              {
                member: "DashboardSection.key",
                operator: "equals",
                values: [sectionKey],
              },
            ]
          : []),
        ...(provider
          ? [
              {
                member: "Account.provider",
                operator: "equals",
                values: [provider],
              },
            ]
          : ([] as $TSFixMe)),
      ],
    },
  ];
}

interface Props {
  dashboardKey: string;
  title: string;
  enableMenu?: boolean;
  sectionKey?: string;
  provider?: string | null;
}

export function Findings({
  dashboardKey,
  sectionKey,
  enableMenu = false,
  provider = null,
  title,
}: Props) {
  const eomDateStr = format(endOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd");
  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const queryCurr = useCubeQuery(
    buildQuery("Yesterday", dashboardKey, provider, sectionKey),
  );

  const queryPrev = useCubeQuery(
    buildQuery([eomDateStr, eomDateStr], dashboardKey, provider, sectionKey),
  );

  const countCurr = queryCurr.resultSet?.tablePivot()[0][
    "ControlSignal.distinctResources"
  ] as number;

  const countPrev = queryPrev.resultSet?.tablePivot()[0][
    "ControlSignal.distinctResources"
  ] as number;

  if (queryCurr.isLoading || queryPrev.isLoading) {
    return (
      <Skeleton
        animation="wave"
        height="100px"
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  const percent = percentChanged(countCurr, countPrev);

  return (
    <StatCard
      description={`Count of findings across all ${title} sections`}
      menu={
        enableMenu ? (
          <FindingsMenu
            dashboardKey={dashboardKey}
            dashboardSectionKey={sectionKey}
          />
        ) : undefined
      }
      message={<PercentChangeMessage percent={percent} />}
      title="Findings"
    >
      {countCurr || 0}
    </StatCard>
  );
}
