import { useContext } from "react";

import { BreadCrumbsHeader } from "app/components/BreadcrumbsHeader";
import { ProviderContext } from "app/contexts";
import { useEnabledDashboards } from "app/hooks";

export default function OverviewBreadcrumbs() {
  const provider = useContext(ProviderContext);
  const dashboards = useEnabledDashboards(provider);

  const breadcrumbs = [
    { label: "Overview" },
    {
      tabs: [
        { label: "Cloud", target: `/dashboards/cloud` },
        { label: "Stacklet Activity", target: "/activity" },
        ...dashboards,
        { label: "Deployed Policies", target: `/policies` },
      ],
    },
  ];

  return <BreadCrumbsHeader breadcrumbs={breadcrumbs} />;
}
