import Typography from "@mui/material/Typography";
import { COLORS } from "@stacklet/ui";

interface Props {
  amount: number;
  currency?: string;
  color?: string;
  compact?: boolean;
  decimals?: boolean;
}

export function Currency({
  amount,
  currency = "USD",
  color = COLORS.orange.base,
  compact = false,
  decimals = true,
}: Props) {
  const formatter = new Intl.NumberFormat("en-US", {
    compactDisplay: compact ? "short" : "long",
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
    currency,
    notation: compact ? "compact" : "standard",
    style: "currency",
  });

  return (
    <Typography sx={{ color: color }} variant="inherit">
      {formatter.format(amount)}
    </Typography>
  );
}
