import LaunchIcon from "@mui/icons-material/Launch";
import MuiLink from "@mui/material/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";

type Target = "_blank" | "_self" | "_parent" | "top";

type Props = { children: React.ReactNode } & (
  | { to: string; href?: never }
  | { href: string; to?: never }
) & {
    activeBgColor?: string;
    activeTextColor?: string;
    textColor?: string;
    bgColor?: string;
    textDecoration?: "underline" | "none";
    target?: Target;
  };

export function Link({
  to,
  href,
  children,
  textColor,
  bgColor,
  activeBgColor,
  activeTextColor,
  textDecoration = "underline",
  target,
}: Props) {
  const location = useLocation();

  const regex = new RegExp(`${to}/*`);

  const sx = {
    backgroundColor: bgColor || "inherit",
    color: textColor || "inherit",
    textDecoration,
    "&.active": {
      backgroundColor: activeBgColor || "inherit",
      color: activeTextColor || textColor || "inherit",
    },
  };

  const returnLink = to ? (
    <MuiLink
      className={
        regex.test(location.pathname) ||
        to === `${location.pathname}${location.search}`
          ? "active"
          : "link"
      }
      component={RouterLink}
      sx={sx}
      target={target || undefined}
      to={to}
    >
      {children}
    </MuiLink>
  ) : (
    <MuiLink
      href={href}
      rel="noopener"
      sx={{ ...sx, alignItems: "center", display: "inline-flex" }}
      target={target || "_blank"}
    >
      {children}
      <LaunchIcon fontSize="inherit" sx={{ ml: 0.5 }} />
    </MuiLink>
  );

  return returnLink;
}
