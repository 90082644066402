import { Suspense, useMemo, useState } from "react";

import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import WebSocketTransport from "@cubejs-client/ws-transport";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { ErrorBoundary } from "react-error-boundary";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay";
import { useLocation } from "react-router-dom";

import { CircularFallback, ErrorFallback } from "app/components/fallbacks";
import Help from "app/components/Help";
import { SideBar } from "app/components/layout";
import { Footer } from "app/components/layout/footer/Footer";
import Scripts from "app/components/Scripts";
import { AlertProvider, HelpContext, SideBarContext } from "app/contexts";
import { type Provider, ProviderContext } from "app/contexts/ProviderContext";
import { UserContextProvider } from "app/contexts/UserContext"; // relay doesn't understand index.ts
import {
  useInitAnalytics,
  useLocationChange,
  useSentry,
  useStickyState,
} from "app/hooks";
import { getAuthToken } from "app/utils/auth";
import { useCubejsConfig } from "app/views/dashboards/hooks/useCubejsConfig";

import { AppRoutes } from "./Routes";

import type { AppQuery } from "__generated__/AppQuery.graphql";

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type $TSFixMe = any;

/* This is a container for the main content that adjusts its width when the
 * Help drawer is opened and closed.
 */
const NonHelpContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  "&.contentShift": {
    marginRight: 0,
    [theme.breakpoints.up(theme.help.breakpoint)]: {
      marginRight: theme.help.width,
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: theme.help.widthXL,
    },
  },
}));

export default function App() {
  const savedProvider = localStorage.getItem("provider");
  const selectedProvider = savedProvider
    ? JSON.parse(savedProvider)
    : ("aws" as Provider);

  const location = useLocation();
  const [provider, setProvider] = useState<Provider>(selectedProvider);

  const data = useLazyLoadQuery<AppQuery>(
    graphql`
      query AppQuery {
        platform {
          cloudCustodianVersion
          version
        }
        ...Footer_platform
        ...UserContext_whoami
        ...Routes_accounts
      }
    `,
    {},
  );

  const { version, cloudCustodianVersion } = data.platform;

  useInitAnalytics(version, cloudCustodianVersion);
  useSentry(version);
  const apiUrl = useCubejsConfig();

  const [helpCollapsed, setHelpCollapsed] = useState(true);
  const [helpPages, setHelpPages] = useState<string[]>([]);
  const [sideBarCollapsed, setSideBarCollapsed] = useStickyState(
    false,
    "isSideBarCollapsed",
  );

  useLocationChange((loc, prevLoc) => {
    if (loc !== prevLoc) {
      setHelpCollapsed(true);
    }
  });

  function handleProviderChange(provider: Provider) {
    setProvider(provider);
  }

  const cubejsApi = useMemo(() => {
    if (apiUrl) {
      return cubejs(() => getAuthToken(), {
        transport: new WebSocketTransport({
          apiUrl,
        }),
        apiUrl,
      });
    }
  }, [apiUrl]);

  if (!cubejsApi) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Scripts />
      <UserContextProvider query={data}>
        <ProviderContext.Provider value={provider}>
          <CubeProvider cubejsApi={cubejsApi}>
            <SideBarContext.Provider
              value={{
                collapsed: sideBarCollapsed,
                toggleCollapsed: () => setSideBarCollapsed(!sideBarCollapsed),
              }}
            >
              <HelpContext.Provider
                value={{
                  collapsed: helpCollapsed,
                  expand: () => setHelpCollapsed(false),
                  collapse: () => setHelpCollapsed(true),
                  toggle: () => setHelpCollapsed(!helpCollapsed),
                  pageNames: helpPages,
                  setPageNames: (pageNames) => setHelpPages(pageNames),
                }}
              >
                <SideBar onProviderChange={handleProviderChange} />
                <Box
                  display="flex"
                  flex="1"
                  flexDirection="column"
                  sx={{ overflow: "auto", height: "100vh" }}
                >
                  <CssBaseline />
                  <Container
                    component="main"
                    maxWidth={false}
                    sx={{
                      marginBottom: 2,
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      width: (theme) =>
                        sideBarCollapsed
                          ? `calc(100% - ${theme.sidebar.widthCollapsed})`
                          : "100%",
                    }}
                  >
                    <ErrorBoundary
                      FallbackComponent={ErrorFallback}
                      resetKeys={[location.pathname]}
                    >
                      <AlertProvider>
                        <Suspense fallback={<CircularFallback />}>
                          <NonHelpContent
                            className={clsx({ contentShift: !helpCollapsed })}
                          >
                            <AppRoutes queryRef={data} />
                          </NonHelpContent>
                          <Help pageNames={helpPages} />
                        </Suspense>
                      </AlertProvider>
                    </ErrorBoundary>
                  </Container>
                  <Footer queryRef={data} />
                </Box>
              </HelpContext.Provider>
            </SideBarContext.Provider>
          </CubeProvider>
        </ProviderContext.Provider>
      </UserContextProvider>
    </Box>
  );
}
