import { useContext } from "react";

import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { ResponsiveLine } from "@nivo/line";
import * as Sentry from "@sentry/browser";
import { COLORS } from "@stacklet/ui";
import { addDays, format, subDays } from "date-fns";
import { useDeepCompareMemo } from "use-deep-compare";

import { ProviderContext } from "app/contexts";
import { ChartErrorBoundary } from "app/views/dashboards/components";

const CHART_COLORS = [COLORS.green.D30, COLORS.tan.D30, COLORS.red.D20];

type Granularity = "day" | "hour" | "minute";

interface Props {
  granularity?: Granularity;
}

export function ActiveResourceCount({ granularity = "day" }: Props) {
  const provider = useContext(ProviderContext);
  const chartTitle = "Cloud resources";
  const today = new Date();
  const dateRange = {
    day: [
      format(subDays(today, 30), "yyyy-MM-dd"),
      format(addDays(today, 1), "yyyy-MM-dd"),
    ],
    hour: "last 168 hours", // 7 days * 24 hours
    minute: "last 60 minutes",
  } as const;

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      measures: [
        "ResourceActivity.added",
        "ResourceActivity.modified",
        "ResourceActivity.removed",
      ],
      timeDimensions: [
        {
          dimension: "ResourceActivity.completed",
          dateRange: dateRange[granularity],
          granularity,
        },
      ],
      order: {
        "ResourceActivity.completed": "asc",
      },
      filters: [
        {
          member: "ResourceActivity.provider",
          operator: "equals",
          values: [provider],
        },
      ],
    },
    { subscribe: granularity === "minute" },
  );

  const data = useDeepCompareMemo(
    () =>
      resultSet?.series().map((s, index) => ({
        id: s.key,
        data: s.series.map((r) => ({
          x: r.x,
          y: r.value,
        })),
        label: s.shortTitle,
        color: CHART_COLORS[index],
      })),
    [resultSet],
  );

  const maxAddedY = data?.length
    ? Math.max(...data[0].data.map((item) => item.y))
    : 0;
  const maxModifiedY = data?.length
    ? Math.max(...data[1].data.map((item) => item.y))
    : 0;
  const maxRemovedY = data?.length
    ? Math.max(...data[2].data.map((item) => item.y))
    : 0;
  const max = Math.max(maxAddedY, maxModifiedY, maxRemovedY) || 100;

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
  }

  if (isLoading || !resultSet) {
    return (
      <>
        <Typography sx={{ mb: 1 }} variant="h3">
          {chartTitle}
        </Typography>
        <Skeleton
          animation="wave"
          height={300}
          role="progressbar"
          sx={{ mb: 2 }}
          variant="rectangular"
        />
      </>
    );
  }

  const axisFormatter = {
    day: "%m/%d",
    hour: "%m/%d",
    minute: "%H:%M",
  };

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h3">
        {chartTitle}
      </Typography>
      <Box sx={{ height: "300px", mb: 2 }}>
        <ChartErrorBoundary>
          {data ? (
            <ResponsiveLine
              axisBottom={{
                format: axisFormatter[granularity],
                tickValues: 5,
              }}
              axisLeft={{
                tickSize: 0,
                tickValues: 5,
                legend: "# Resources",
                legendOffset: -50,
                legendPosition: "end",
                format: (e) => (Math.floor(e) === e ? e : ""), // only integer ticks
              }}
              colors={CHART_COLORS}
              data={data}
              enableSlices="x"
              legends={[
                {
                  anchor: "bottom-right",
                  data: data.map((i) => ({
                    id: i.id,
                    color: i.color,
                    label: i.label,
                  })),
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              margin={{ top: 50, right: 110, bottom: 32, left: 60 }}
              xFormat="time:%Y-%m-%dT%H:%M:%S.%L"
              xScale={{
                type: "time",
                format: "%Y-%m-%dT%H:%M:%S.%L",
                precision: granularity,
              }}
              yScale={{
                type: "linear",
                min: 0,
                max: max + 0.1 * max || 100,
              }}
            />
          ) : null}
        </ChartErrorBoundary>
      </Box>
    </>
  );
}
