/**
 * @generated SignedSource<<e412a965dc36c35f0e83b267a76d4a03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionModalQuery$variables = {
  key?: string | null | undefined;
  sectionKey?: string | null | undefined;
};
export type SectionModalQuery$data = {
  readonly dashboard: {
    readonly section: {
      readonly " $fragmentSpreads": FragmentRefs<"Remediations_recommendedActions" | "SectionModalDetails_section" | "TopAccounts_topAccounts">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SectionModalResources_dashboard">;
  } | null | undefined;
};
export type SectionModalQuery = {
  response: SectionModalQuery$data;
  variables: SectionModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sectionKey"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "sectionKey",
    "variableName": "sectionKey"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "dashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DashboardSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Remediations_recommendedActions"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SectionModalDetails_section"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TopAccounts_topAccounts"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SectionModalResources_dashboard"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SectionModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "dashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DashboardSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recommendedActions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numeral",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DashboardSection",
                "kind": "LinkedField",
                "name": "topSection",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "number",
                    "value": 5
                  }
                ],
                "concreteType": "AccountCost",
                "kind": "LinkedField",
                "name": "topAccounts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceCost",
                    "kind": "LinkedField",
                    "name": "cost",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "multiplier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalDays",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "topAccounts(number:5)"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metric",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c9c9fc9481b524c9e67b68da1a82272",
    "id": null,
    "metadata": {},
    "name": "SectionModalQuery",
    "operationKind": "query",
    "text": "query SectionModalQuery(\n  $key: String\n  $sectionKey: String\n) {\n  dashboard(key: $key) {\n    section(sectionKey: $sectionKey) {\n      ...Remediations_recommendedActions\n      ...SectionModalDetails_section\n      ...TopAccounts_topAccounts\n      id\n    }\n    ...SectionModalResources_dashboard\n    id\n  }\n}\n\nfragment Remediations_recommendedActions on DashboardSection {\n  recommendedActions\n}\n\nfragment SectionModalDetails_section on DashboardSection {\n  description\n  key\n  numeral\n  title\n  topSection {\n    title\n    id\n  }\n}\n\nfragment SectionModalResources_dashboard on Dashboard {\n  key\n  metric\n  section(sectionKey: $sectionKey) {\n    key\n    id\n  }\n  title\n}\n\nfragment TopAccounts_topAccounts on DashboardSection {\n  topAccounts(number: 5) {\n    account {\n      id\n      name\n    }\n    cost {\n      currency\n      multiplier\n      totalCost\n      totalDays\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2deca9643aa9de7ede688329ed6826fa";

export default node;
