/**
 * @generated SignedSource<<e00613421a83f59a400ca7af7a525e30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderPolicyDetails_policy$data = {
  readonly commit: {
    readonly author: string;
    readonly date: string;
  };
  readonly description: string | null | undefined;
  readonly mode: string;
  readonly name: string;
  readonly provider: CloudProvider;
  readonly resourceType: string;
  readonly severity: string | null | undefined;
  readonly version: number;
  readonly " $fragmentSpreads": FragmentRefs<"Actions_policy">;
  readonly " $fragmentType": "HeaderPolicyDetails_policy";
};
export type HeaderPolicyDetails_policy$key = {
  readonly " $data"?: HeaderPolicyDetails_policy$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderPolicyDetails_policy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderPolicyDetails_policy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoCommit",
      "kind": "LinkedField",
      "name": "commit",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "author",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Actions_policy"
    }
  ],
  "type": "Policy",
  "abstractKey": null
};

(node as any).hash = "a9dcf260342f91d8b2eb09734c835c75";

export default node;
